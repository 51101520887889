import { useCallback, useEffect, useState } from "react";
import Chart, { ChartList } from "../../components/charts/Chart";
import Loader from "../../components/Loader";
import NavPills from "../../components/NavPills";
import { Transaction } from "../../types/movements";
import { HistoryTimeFrame } from "./history/PatrimonyHistory";
import { NavPillsContainer } from "./Patrimony";

const timeFrameOptions: Array<{
  id: HistoryTimeFrame;
  label: string;
  miniLabel?: string;
}> = [
    { id: "UP_TO_1_MONTH", label: "1 mois", miniLabel: "1 mois" },
    { id: "UP_TO_3_MONTHS", label: "3 mois", miniLabel: "3m" },
    { id: "UP_TO_6_MONTHS", label: "6 mois", miniLabel: "6m" },
    { id: "UP_TO_1_YEAR", label: "1 an", miniLabel: "1 an" },
    { id: "UP_TO_3_YEARS", label: "3 ans", miniLabel: "3a" },
    { id: "ALL", label: "Tout" },
  ];

type SavingChartProps = {
  transactions: Transaction[];
};

const SavingChart = (props: SavingChartProps) => {
  const [timeFrame, setTimeFrame] = useState<HistoryTimeFrame>("ALL");
  const [allTimeFramesData, setAllTimeFramesData] =
    useState<Map<HistoryTimeFrame, ChartList[]>>();

  const generateDailyDates = (startDate: Date, endDate: Date): string[] => {
    const dates: string[] = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
      dates.push(new Date(currentDate).toISOString().split('T')[0]); // Format YYYY-MM-DD
      currentDate.setDate(currentDate.getDate() + 1); // Increment by 1 day
    }

    return dates;
  };

  const loadAllTimeFramesData = useCallback(() => {
    const timeFrames: HistoryTimeFrame[] = timeFrameOptions.map((x) => x.id);

    for (const timeFrame of timeFrames) {
      let filteredTransactions = filterTransactionByTimeFrame(
        props.transactions,
        timeFrame
      );

      if (filteredTransactions.length === 0) continue;

      filteredTransactions.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime());

      const startDate = new Date(filteredTransactions[0].date);
      const endDate = new Date();

      const allDates = generateDailyDates(startDate, endDate);

      let lastValue = 0;

      const overAll = allDates.map((date): ChartList => {
        const transaction = filteredTransactions.find((t) => t.date.split('T')[0] === date);

        if (transaction) {
          lastValue = transaction.value ?? lastValue;
        }

        return {
          date,
          otherAssets: 0,
          realEstateAndOtherAssets: 0,
          brut: lastValue, // If no transaction on the date, keep the last known value
          net: lastValue,
        };
      });

      setAllTimeFramesData((prevData) => {
        const newData = new Map(prevData);
        newData.set(timeFrame, overAll);
        return newData;
      });
    }
  }, [props.transactions]);

  useEffect(() => {
    loadAllTimeFramesData();
  }, [loadAllTimeFramesData, timeFrame]);

  const filterTransactionByTimeFrame = (
    transactions: Transaction[],
    timeFrame: HistoryTimeFrame
  ) => {
    return transactions.filter((t) => {
      const date = new Date(t.date);
      const now = new Date();
      switch (timeFrame) {
        case "UP_TO_1_MONTH":
          return date > new Date(now.setMonth(now.getMonth() - 1));
        case "UP_TO_3_MONTHS":
          return date > new Date(now.setMonth(now.getMonth() - 3));
        case "UP_TO_6_MONTHS":
          return date > new Date(now.setMonth(now.getMonth() - 6));
        case "UP_TO_1_YEAR":
          return date > new Date(now.setFullYear(now.getFullYear() - 1));
        case "UP_TO_3_YEARS":
          return date > new Date(now.setFullYear(now.getFullYear() - 3));
        case "ALL":
          return true;
        default:
          return false;
      }
    });
  };

  return (
    <div>
      <NavPillsContainer>
        <NavPills
          options={timeFrameOptions}
          onOptionSelected={setTimeFrame}
          selectedOptionId={timeFrame}
          widthMini="410px"
        />
      </NavPillsContainer>
      <div>
        {!allTimeFramesData || !allTimeFramesData.get(timeFrame) ? (
          <Loader />
        ) : (
          <Chart
            type="net"
            timeFrame={timeFrame}
            list={allTimeFramesData.get(timeFrame) || []}
            hideMarker={true}
          />
        )}
      </div>
    </div>
  );
};

export default SavingChart;
